class HTTP {
    getBaseUrl = () =>{
        let url;
        switch(process.env.NODE_ENV) {
          case 'production':
            url = 'http://ec2-3-145-172-250.us-east-2.compute.amazonaws.com:8080';
            break;
          case 'development':
          default:
            url = 'http://localhost:8080';
        }
      
        return url;
    }
}

export default new HTTP();