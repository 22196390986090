import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Dashboard from "./Dashbaord";
import EnrolledUsers from "./member/EnrolledUsers";
import Renewals from "./member/Renewals";
import EnrollPdf from "./EnrollPdf";
import PrepaidUsers from "./member/PrepaidUsers";
import Settings from "./Settings";
import { Layout, Menu, Avatar } from "antd";
// import { createFromIconfontCN } from "@ant-design/icons";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    DashboardOutlined,
    FileTextOutlined,
    VideoCameraOutlined,
    PhoneOutlined,
    PlusOutlined,
    AimOutlined,
    BellOutlined
} from "@ant-design/icons";

import './admin.css'
import OldUsers from "./member/OldUsers";
import AddUser from "./member/AddUser";
import Member from "./member/Member";
import AddLiveClass from "./class/AddLiveClass";
import ViewLiveClass from "./class/ViewLiveClass";
import ClassUsers from "./class/ClassUsers";
import AddFitnessGoal from "./fitness/AddFitnessGoal";
import ViewFitnessGoal from "./fitness/ViewFitnessGoal";
import FitnessGoalUsers from "./fitness/FitnessGoalUsers";
import AddMentor from "./mentor/AddMentor";
import ViewMentor from "./mentor/ViewMentor";
import MentorUsers from "./mentor/MentorUsers";
import AddMedicalCondition from "./medical/AddMedicalCondition";
import ViewMedicalCondition from "./medical/ViewMedicalCondition";
import MedicalConditionUsers from "./medical/MedicalConditionUsers";
// const IconFont = createFromIconfontCN({
//     scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
// });

const { Header, Sider, Content } = Layout;

const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;

const menuMap = {
    dashboard: "1",
    enrolledUsers: "2",
    renewals: "3",
    prepaidUsers: "4",
    oldUsers: "5",
    addUser: "6",
    enrollPdf: "7",
    addClass: "8",
    viewClasses: "9",
    classUsers: "10",
    addFitnessGoal: "11",
    viewFitnessGoals: "12",
    fitnessGoalUsers: "13",
    addMentor: "14",
    viewMentors: "15",
    mentorUsers: "16",
    addMedicalCondition: "17",
    viewMedicalConditions: "18",
    medicalConditionUsers: "19",
    settings: "20",
}
const navigationMap = {
    dashboard: "Dashboard",
    enrolledUsers: "Enrolled Users",
    renewals: "Renewals",
    prepaidUsers: "Prepaid Users",
    oldUsers: "Expired Users",
    addUser: "Add User",
    enrollPdf: "Enrollment Pdfs",
    addClass: "Add Batch",
    viewClasses: "View Batches",
    classUsers: "Batch Users",
    addFitnessGoal: "Add Fitness Goal",
    viewFitnessGoals: "View Fitnesss Goals",
    fitnessGoalUsers: "Fitness Goal Users",
    addMentor: "Add Mentor",
    viewMentors: "View Mentors",
    mentorUsers: "Mentor Users",
    addMedicalCondition: "Add Medical Condition",
    viewMedicalConditions: "View Medical Conditions",
    medicalConditionUsers: "Medical Condition USers",
    settings: "Settings",
};
class Admin extends Component {
    constructor(props) {
        super(props);
        let href = window.location.href.split("/");


        this.state = {
            collapsed: false,
            navigation:
                navigationMap[href[4]] !== undefined
                    ? navigationMap[href[4]]
                    : "Dashboard",
            defaultSelec: menuMap[href[4]] !== undefined ? menuMap[href[4]] : "1",
        };
    }
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    setMenuState = (e) =>{
        this.setState({
            navigation: navigationMap[e],
            defaultSelec: menuMap[e],
        });
    }
    handleLogout = () => {
        window.location.href = "/login";
    };

    render() {
        return (
            <Layout style={{ minHeight: "100vh" }}>
                <Router>
                    <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                        <div
                            className="logo"
                            onClick={this.setDashboardState}
                            style={{
                                backgroundColor: "unset",
                                height: "63px",
                                display: "flex",
                                backgroundImage: "url(../vajra.png)",
                                backgroundSize: "100% 100%",
                                marginTop: "0",
                                marginLeft: "0",
                                marginRight: "0",
                            }}
                        >
                            <Link
                                to="/admin/dashboard"
                                style={{
                                    width: "100%",
                                    display: "block",
                                }}
                            ></Link>
                            {/* <Avatar
                                style={
                                    {
                                        display: 'inline-block',
                                        width: '60px',
                                        height: '40px'
                                    }
                                }
                                src="/logo.png" /> */}
                            {/* <span
                                style={
                                    {
                                        color: 'white',
                                        width: '60px',
                                        height: '60px',
                                        lineHeight: '60px',
                                        fontSize: '24px',
                                        textAlign: 'center',
                                        display: 'inline-block',
                                        float: 'right',
                                        fontWeight: '800'
                                    }
                                }>
                            </span> */}
                        </div>

                        <Menu
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={[this.state.defaultSelec]}
                        >
                            <Menu.Item
                                key="1"
                                icon={<DashboardOutlined />}
                                onClick={() => this.setMenuState("dashboard")}
                            >
                                <Link className="nav-link" to="/admin/dashboard">
                                    Dashboard
                                </Link>
                            </Menu.Item>
                            <SubMenu title="Users" icon={<UserOutlined />} key="sub1">
                                <Menu.Item
                                    key="2"
                                    onClick={() => this.setMenuState("enrolledUsers")}
                                >
                                    <Link className="nav-link" to="/admin/enrolledUsers">
                                        EnrolledUsers
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="3"
                                    onClick={() => this.setMenuState("renewals")}
                                >
                                    <Link className="nav-link" to="/admin/renewals">
                                        Renewals
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="4"
                                    onClick={() => this.setMenuState("prepaidUsers")}
                                >
                                    <Link className="nav-link" to="/admin/prepaidUsers">
                                        Prepaid Users
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="5"
                                    onClick={() => this.setMenuState("oldUsers")}
                                >
                                    <Link className="nav-link" to="/admin/oldUsers">
                                        Old Users
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="6"
                                    onClick={() => this.setMenuState("addUser")}
                                >
                                    <Link className="nav-link" to="/admin/addUser">
                                        Add User
                                    </Link>
                                </Menu.Item>
                            </SubMenu>

                            <Menu.Item
                                key="7"
                                icon={<FileTextOutlined />}
                                onClick={() => this.setMenuState("enrollPdf")}
                            >
                                <Link className="nav-link" to="/admin/enrollPdf">
                                    EnrollPdf
                                </Link>
                            </Menu.Item>

                            <SubMenu  key="sub2"  title="Batches"
                                icon={<VideoCameraOutlined />}
                            >
                                <Menu.Item
                                    key="8"
                                    onClick={() => this.setMenuState("addClass")}
                                >
                                    <Link className="nav-link" to="/admin/addClass">
                                        Add
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="9"
                                    onClick={() => this.setMenuState("viewClasses")}
                                >
                                    <Link className="nav-link" to="/admin/viewClasses">
                                        View
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="10"
                                    onClick={() => this.setMenuState("classUsers")}
                                >
                                    <Link className="nav-link" to="/admin/classUsers">
                                        Users
                                    </Link>
                                </Menu.Item>
                            </SubMenu>

                            <SubMenu  key="sub3"  title="Fitness Goal"
                                icon={<AimOutlined />}
                            >
                                <Menu.Item
                                    key="11"
                                    onClick={() => this.setMenuState("addFitnessGoal")}
                                >
                                    <Link className="nav-link" to="/admin/addFitnessGoal">
                                        Add
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="12"
                                    onClick={() => this.setMenuState("viewFitnessGoals")}
                                >
                                    <Link className="nav-link" to="/admin/viewFitnessGoals">
                                        View
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="13"
                                    onClick={() => this.setMenuState("fitnessGoalUsers")}
                                >
                                    <Link className="nav-link" to="/admin/fitnessGoalUsers">
                                        Users
                                    </Link>
                                </Menu.Item>
                            </SubMenu>


                            <SubMenu key="sub4"  title="Mentors"
                                icon={<PhoneOutlined />}
                            >
                                <Menu.Item
                                    key="14"
                                    onClick={() => this.setMenuState("addMentor")}
                                >
                                    <Link className="nav-link" to="/admin/addMentor">
                                        Add
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="15"
                                    onClick={() => this.setMenuState("viewMentors")}
                                >
                                    <Link className="nav-link" to="/admin/viewMentors">
                                        View
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="16"
                                    onClick={() => this.setMenuState("mentorUsers")}
                                >
                                    <Link className="nav-link" to="/admin/mentorUsers">
                                        Users
                                    </Link>
                                </Menu.Item>
                            </SubMenu>

                            <SubMenu
                                key="sub5"
                                title="Medical "
                                icon={<PlusOutlined />}
                            >
                                <Menu.Item
                                    key="17"
                                    onClick={() => this.setMenuState("addMedicalCondition")}
                                >
                                    <Link className="nav-link" to="/admin/addMedicalCondition">
                                        Add
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="18"
                                    onClick={() => this.setMenuState("viewMedicalConditions")}
                                >
                                    <Link className="nav-link" to="/admin/viewMedicalConditions">
                                        View
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="19"
                                    onClick={() => this.setMenuState("medicalConditionUsers")}
                                >
                                    <Link className="nav-link" to="/admin/medicalConditionUsers">
                                        Users
                                    </Link>
                                </Menu.Item>
                            </SubMenu>

                            {/* <Menu.Item key="6" name="Logout" icon={<FileTextOutlined />} onClick={this.handleLogout} >
                                {<Link className="nav-link" to=''>Logout</Link>}
                                </Menu.Item> */}
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Header
                            className="site-layout-background"
                            style={{ padding: 0, height: "63px" }}
                        >
                            {React.createElement(
                                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                                {
                                    className: "trigger",
                                    onClick: this.toggle,
                                }
                            )}
                            <span>{this.state.navigation}</span>

                            <Menu
                                mode="horizontal"
                                style={{
                                    display: "inline-block",
                                    float: "right",
                                    marginRight: "40px",
                                }}
                                onClick={false}
                            >
                                <SubMenu title={<span> <BellOutlined /></span>} >
                                        <Menu.Item key="notification:1">No Notifications</Menu.Item>
                                    </SubMenu>

                                <SubMenu
                                    key="headersub1"
                                    title={
                                        <span>
                                            <Avatar
                                                style={{
                                                    backgroundColor: "#f56a00",
                                                    verticalAlign: "middle",
                                                }}
                                            >
                                                A{" "}
                                            </Avatar>
                                        </span>
                                    }
                                >
                                    {/* <MenuItemGroup title="Item 1"> */}
                                    <Menu.Item key="setting:1"  onClick={() => this.setMenuState("settings")}>
                                        {" "}
                                        <Link className="nav-link" to="/admin/settings">
                                            Settings
                                        </Link>
                                    </Menu.Item>
                                    {/* <Menu.Item key="setting:2" onClick={this.handleLogout}>
                                        Logout
                                    </Menu.Item> */}
                                    {/* </MenuItemGroup> */}
                                    {/* <MenuItemGroup title="Item 2">
                                            <Menu.Item key="setting:3">Option 3</Menu.Item>
                                            <Menu.Item key="setting:4">Option 4</Menu.Item>
                                        </MenuItemGroup> */}
                                </SubMenu>
                            </Menu>
                        </Header>
                        <Content
                            className="site-layout-background"
                            style={{
                                backgroundColor: "#f2f2f2",
                                height: "calc( 100vh - 63px )",
                                overflow: "auto",
                            }}
                        >
                            {" "}
                            <Routes>
                                <Route
                                    path="/"
                                    exact
                                    element={<Dashboard />}
                                />
                                <Route
                                    path="/admin"
                                    exact
                                    element={<Dashboard />}
                                />
                                <Route
                                    path="/admin/dashboard"
                                    exact
                                    element={<Dashboard />}
                                />
                                <Route
                                    path="/admin/enrolledUsers"
                                    element={<EnrolledUsers />}
                                />
                                <Route
                                    path="/admin/renewals"
                                    element={<Renewals />}
                                />
                                <Route
                                    path="/admin/enrollPdf"
                                    element={<EnrollPdf />}
                                />
                                <Route
                                    path="/admin/prepaidUsers"
                                    element={<PrepaidUsers />}
                                />
                                <Route
                                    path="/admin/oldUsers"
                                    element={<OldUsers />}
                                />
                                <Route
                                    path="/admin/addUser"
                                    element={<AddUser />}
                                />
                                <Route
                                    path="/admin/member/:memberId"
                                    element={<Member />}
                                />
                                <Route
                                    path="/admin/addClass/"
                                    element={<AddLiveClass />}
                                />
                                <Route
                                    path="/admin/viewClasses/"
                                    element={<ViewLiveClass />}
                                />
                                <Route
                                    path="/admin/classUsers/"
                                    element={<ClassUsers />}
                                />
                                <Route
                                    path="/admin/addFitnessGoal/"
                                    element={<AddFitnessGoal />}
                                />
                                <Route
                                    path="/admin/viewFitnessGoals/"
                                    element={<ViewFitnessGoal />}
                                />
                                <Route
                                    path="/admin/fitnessGoalUsers/"
                                    element={<FitnessGoalUsers />}
                                />
                                <Route
                                    path="/admin/addMentor/"
                                    element={<AddMentor />}
                                />
                                <Route
                                    path="/admin/viewMentors/"
                                    element={<ViewMentor />}
                                />
                                <Route
                                    path="/admin/mentorUsers/"
                                    element={<MentorUsers />}
                                />
                                <Route
                                    path="/admin/addMedicalCondition/"
                                    element={<AddMedicalCondition />}
                                />
                                <Route
                                    path="/admin/viewMedicalConditions/"
                                    element={<ViewMedicalCondition />}
                                />
                                <Route
                                    path="/admin/medicalConditionUsers/"
                                    element={<MedicalConditionUsers />}
                                />
                                <Route path="/admin/settings" element={Settings} />
                                {/* <Route path="/logout" component={Logout} /> */}
                                {/* <Route element={<Dashboard  />} /> */}
                            </Routes>
                        </Content>
                    </Layout>
                </Router>
            </Layout>
        );
    }
}

export default Admin;