import { React, Component } from 'react';
import { Row, Col, Modal, Button, Input, Form } from 'antd';
import Api from '../../utils/Api';
class AddMedicalCondition extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visibleAddModal: false,
            loadingAddModal: false,

        }

        this.showAddModal = this.showAddModal.bind(this)
        this.cancelAdd = this.cancelAdd.bind(this)
        this.okAdd = this.okAdd.bind(this)

        this.addMedicalCondition = this.addMedicalCondition.bind(this)

    }

    showAddModal() {
        this.setState({
            visibleAddModal: true
        })
    }
    cancelAdd() {
        this.setState({
            visibleAddModal: false
        })
    }
    okAdd() {
        this.setState({
            loadingAddModal: true
        })
    }
    addMedicalCondition(e) {
        console.log(e)
        fetch(Api.addMedicalCondition(), {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                name: e.name
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error === false) {
                    this.setState({
                        visibleAddModal: false,
                        loadingAddModal: false,
                    })
                    Modal.success({
                        content: "Medical Condition Added",
                    });
                } else {
                    this.setState({
                        visibleAddModal: false,
                        loadingAddModal: false,
                    })
                    Modal.error({
                        title: "Error",
                        content: "Medical Condition Not Added",
                    });
                }


            })
            .catch((err) => {
                console.log(err)
                this.setState({
                    visibleAddModal: false,
                    loadingAddModal: false,
                })
                Modal.error({
                    title: "Error",
                    content: "Medical Condition Not Added",
                });
            });
    }


    render() {
        return (
            <div style={{
                padding: "20px",
                minHeight: "80vh",
                overflow: "auto",
                position: "relative",
                width: "100%",
            }}>
                <Row>

                    <Col span={24}>
                        <Button type="primary" onClick={this.showAddModal}>Add Medical Condition</Button>
                    </Col>
                </Row>
                {/* Add Modal */}
                <Modal
                    title="Add Medical Condition"
                    open={this.state.visibleAddModal}
                    onCancel={this.cancelAdd}
                    footer={[
                        <Button key="back" onClick={this.cancelAdd}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            form="addForm"
                            loading={this.state.loadingAddModal}
                            onClick={this.okAdd}
                        >
                            Add
                        </Button>,
                    ]}
                >
                    <Form
                        id="addForm"
                        name="addForm"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 14,
                        }}
                        onFinish={this.addMedicalCondition}
                        layout="horizontal"
                        labelAlign="left"
                        hideRequiredMark={true}
                    >

                        <Form.Item
                            label="Medical Condition"
                            name="name"
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default AddMedicalCondition;