import { React, Component } from 'react';
import { Row, Col, Table, Select } from 'antd';
import { Link } from "react-router-dom";
import Api from '../../utils/Api';
const { Option } = Select;

class FitnessGoalUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datasource: [],
            columns: [
                {
                    title: 'S.No',
                    dataIndex: 'sno',
                    key: 'sno'
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    // ...this.getColumnSearchProps("name")
                },
                {
                    title: 'Number',
                    dataIndex: 'number',
                    key: 'number',
                    // ...this.getColumnSearchProps("number")
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    // ...this.getColumnSearchProps("email")
                },
                {
                    title: 'Start Date',
                    dataIndex: 'startDate',
                    key: 'startDate',
                    // ...this.getColumnSearchProps("startDate")
                },
                {
                    title: 'End Date',
                    dataIndex: 'endDate',
                    key: 'endtDate',
                    // ...this.getColumnSearchProps("endDate")
                },
                {
                    title: 'Current Fee',
                    dataIndex: 'fee',
                    key: 'fee'
                },
                {
                    title: 'Batch',
                    dataIndex: 'batch',
                    key: 'batch'
                },
                {
                    title: 'Action',
                    key: 'operation',
                    fixed: 'right',
                    width: 100,
                    render: (text, record) => <Link className="nav-link" to={"/admin/member/" + record.key}>open</Link>,
                }
            ],
            fitnessList: []
        }
        this.fetchAllFitnessGoals = this.fetchAllFitnessGoals.bind(this)
        this.fetchAllMembers = this.fetchAllMembers.bind(this)
        this.fetchMembersByFitnessId = this.fetchMembersByFitnessId.bind(this)
    }
    componentDidMount() {
        this.fetchAllFitnessGoals()
        this.fetchAllMembers()
    }
    fetchAllFitnessGoals() {
        fetch(Api.fetchAllFitnessGoals(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let fitnessGoals = response.response;
                    let data = []
                    for (let i = 0; i < fitnessGoals.length; i++) {
                        let obj = {
                            id: fitnessGoals[i].id,
                            name: fitnessGoals[i].name
                        }
                        data.push(obj)
                    }
                    this.setState({
                        fitnessList: data
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchAllMembers() {
        fetch(Api.fetchAllMembers(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let members = response.response;
                    let data = []
                    for (let i = 0; i < members.length; i++) {
                        let obj = {
                            key: members[i].id,
                            name: members[i].name,
                            sno: (i + 1),
                            number: members[i].number,
                            email: members[i].email,
                            startDate: members[i].startDate,
                            endDate: members[i].endDate,
                            fee: members[i].subscriptionAmount,
                            batch: members[i].className
                        }
                        data.push(obj)
                    }
                    this.setState({
                        datasource: data
                    })
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }

    fetchMembersByFitnessId(e) {
        fetch(Api.fetchMembersByFitnessId(), {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                id: e
            }),

        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    if (response.response.members !== undefined || response.response.members !== null) {
                        let members = response.response.members;
                        let data = []
                        for (let i = 0; i < members.length; i++) {
                            let obj = {
                                key: members[i].id,
                                name: members[i].name,
                                sno: (i + 1),
                                number: members[i].number,
                                email: members[i].email,
                                startDate: members[i].startDate,
                                endDate: members[i].endDate,
                                fee: members[i].subscriptionAmount,
                                batch: members[i].className
                            }
                            data.push(obj)
                        }
                        this.setState({
                            datasource: data
                        })
                    }else{
                        this.setState({
                            datasource: []
                        }) 
                    }

                }

            })
            .catch((err) => {
                console.log(err)
            });
    }

    render() {
        return (
            <div style={{
                padding: "20px",
                minHeight: "80vh",
                overflow: "auto",
                position: "relative",
                width: "100%",
            }}>
                <Row>
                    <Col span={24}>
                        <Select placeholder="Fitness Goal" onChange={this.fetchMembersByFitnessId}>
                            {this.state.fitnessList.map((items) => (
                                <Option value={items.id}>{items.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={24}>
                        <Table dataSource={this.state.datasource} columns={this.state.columns} />
                    </Col>
                </Row>

            </div>
        )
    }
}

export default FitnessGoalUsers;