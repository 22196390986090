import React, { Component } from "react";
import { Table, Button, Modal, Row, Col, Card } from "antd";
import Api from "../utils/Api";


class EnrollPdf extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datasource: [],
            columns: [

                {
                    title: 'S.No',
                    dataIndex: 'sno',
                    key: 'sno'
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: 'Start Date',
                    dataIndex: 'startDate',
                    key: 'startDate'
                },
                {
                    title: 'End Date',
                    dataIndex: 'endDate',
                    key: 'endDate'
                },
                {
                    title: 'Current Fee',
                    dataIndex: 'fee',
                    key: 'fee'
                },
                {
                    title: 'Action',
                    dataIndex: 'created',
                    fixed: 'right',
                    key: 'operation',
                    width: 100,
                    render: (created, record) => (
                        <>
                            {record.created === true ? "" : <Button type="primary" onClick={() => this.sendPdf(record)} > Make Pdf </Button>}

                        </>
                    ),
                },

            ]
        }

        this.fetchAllEntries = this.fetchAllEntries.bind(this)
        this.makePdf = this.sendPdf.bind(this)
    }


    componentDidMount() {
        this.fetchAllEntries()
    }

    sendPdf(e) {
        fetch(Api.sendPdf() + "/" + e.key, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    console.log(response.response)
                    Modal.success({
                        title: "File",
                        content: "File Sent",
                    })
                    this.fetchAllEntries();
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }

    fetchAllEntries() {
        fetch(Api.fetchAllEntries(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let enrolls = response.response;
                    let data = []
                    for (let i = 0; i < enrolls.length; i++) {
                        let obj = {
                            key: enrolls[i].fileId,
                            sno: (i + 1),
                            name: enrolls[i].memberName,
                            startDate: enrolls[i].startDate,
                            endDate: enrolls[i].endDate,
                            fee: enrolls[i].subscriptionAmount,
                            memberId: enrolls[i].memberId,
                            created: enrolls[i].isCreated

                        }
                        data.push(obj)
                    }
                    this.setState({
                        datasource: data
                    })
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }
    render() {
        return (
            <div style={{
                padding: "20px",
                minHeight: "80vh",
                overflow: "auto",
                position: "relative",
                width: "100%",
            }}>
                <Row>
                    <Col span={24}>
                        <Card title="Table">
                            <Table dataSource={this.state.datasource} columns={this.state.columns} />
                        </Card>

                    </Col>
                    <Col span={24}>

                    </Col>

                </Row>
            </div>
        );
    }
}

export default EnrollPdf