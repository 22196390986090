import React, { Component } from "react";
import { Row, Col, Modal, Button, Table, Input, Form } from 'antd';
import Api from "../../utils/Api";

class ViewMentor extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visibleUpdateModal: false,
            loadingUpdateModal: false,
            currentMentor:null,
            datasource: [],
            columns: [

                {
                    title: 'S.No',
                    dataIndex: 'sno',
                    key: 'sno'
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Edit',
                    dataIndex: '',
                    key: 'edit',
                    render:(text,record)=>(
                        <Button onClick={()=>this.showUpdateModal(record)}>Update</Button>
                    )
                },
                {
                    title: 'Delete',
                    dataIndex: '',
                    key: 'delete',
                    render:(text,record)=>(
                        <Button onClick={()=>this.removeMentor(record)}>Delete</Button>
                    )
                },
            ]
        }
        this.fetchAllMentors = this.fetchAllMentors.bind(this)

        this.removeMentor = this.removeMentor.bind(this)
        this.updateMentorName = this.updateMentorName.bind(this)

        this.showUpdateModal = this.showUpdateModal.bind(this)
        this.cancelUpdate= this.cancelUpdate.bind(this)
        this.okUpdate = this.okUpdate.bind(this)

    }

    componentDidMount(){
        this.fetchAllMentors()
    }

    fetchAllMentors() {
        fetch(Api.fetchAllMentors(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let mentors = response.response;
                    let data = []
                    for (let i = 0; i < mentors.length; i++) {
                        let obj = {
                            key: mentors[i].id,
                            name: mentors[i].name,
                            sno: (i + 1)
                        }
                        data.push(obj)
                    }
                    this.setState({
                        datasource: data
                    })
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }
    removeMentor(e){
        console.log(e)
        fetch(Api.removeMentor(), {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                id: e.key
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error === false) {
                   
                    Modal.success({
                        content: "Mentor Removed",
                    });
                } else {
                    Modal.error({
                        title: "Error",
                        content: "Mentor Not Removed",
                    });
                }
                this.fetchAllMentors()

            })
            .catch((err) => {
                console.log(err)
                Modal.error({
                    title: "Error",
                    content: "Mentor Not Removed",
                });
            });
    }
    showUpdateModal(e) {
        this.setState({
            visibleUpdateModal: true,
            currentMentor:e.key
        })
    }
    cancelUpdate() {
        this.setState({
            visibleUpdateModal: false
        })
    }
    okUpdate() {
        this.setState({
            loadingUpdateModal: true
        })
    }
    updateMentorName(e){
        console.log(e)
        fetch(Api.updateMentorName(), {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                name: e.name,
                id:this.state.currentMentor
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error === false) {
                    this.setState({
                        visibleUpdateModal: false,
                        loadingUpdateModal: false,
                    })
                    Modal.success({
                        content: "Mentor Updated",
                    });
                } else {
                    this.setState({
                        visibleUpdateModal: false,
                        loadingUpdateModal: false,
                    })
                    Modal.error({
                        title: "Error",
                        content: "Mentor Not Updated",
                    });
                }
                this.fetchAllMentors()

            })
            .catch((err) => {
                console.log(err)
                this.setState({
                    visibleUpdateModal: false,
                    loadingUpdateModal: false,
                })
                Modal.error({
                    title: "Error",
                    content: "Mentor Not Updated",
                });
            });

    }


    render() {
        return (
            <div style={{
                padding: "20px",
                minHeight: "80vh",
                overflow: "auto",
                position: "relative",
                width: "100%",
            }}>
                <Row>
                    {/* <Col span={24}>
                        <Button type="primary" onClick={this.fetchAllMentors}>Load List</Button>
                    </Col> */}
                    <Col span={24}>
                        <Table dataSource={this.state.datasource} columns={this.state.columns} />
                    </Col>
                </Row>

                {/* Update Modal */}
                <Modal
                    title="Update Batch"
                    open={this.state.visibleUpdateModal}
                    onCancel={this.cancelUpdate}
                    footer={[
                        <Button key="back" onClick={this.cancelUpdate}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            form="updateForm"
                            loading={this.state.loadingUpdateModal}
                            onClick={this.okUpdate}
                        >
                            Update Batch
                        </Button>,
                    ]}
                >
                    <Form
                        id="updateForm"
                        name="updateForm"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 14,
                        }}
                        onFinish={this.updateMentorName}
                        layout="horizontal"
                        labelAlign="left"
                        hideRequiredMark={true}
                    >

                        <Form.Item
                            label="Mentor Name"
                            name="name"


                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }

}

export default ViewMentor;