import React, { Component } from "react";
import { Card, Col, Row } from 'antd';
import Api from "../utils/Api";
class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            enrolled: [],
            renewals: [],
            prepaid: [],
            batchList: []
        }
    }

    componentDidMount() {
        this.fetchAllClasses()
        this.fetchEnrolledMembers()
        this.fetchPrepaidMembers()
        this.fetchRenewalGoneMembers()
    }
    fetchAllClasses() {
        fetch(Api.fetchAllClasses(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let classes = response.response;
                    let data = []
                    for (let i = 0; i < classes.length; i++) {
                        let obj = {
                            id: classes[i].id,
                            name: classes[i].name
                        }
                        data.push(obj)
                    }
                    this.setState({
                        batchList: data
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchEnrolledMembers() {
        fetch(Api.fetchEnrolledMembers(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let members = response.response;
                    let data = []
                    for (let i = 0; i < members.length; i++) {
                        let obj = {
                            key: members[i].id,
                            name: members[i].name,
                            sno: (i + 1),
                            number: members[i].number,
                            email: members[i].email,
                            startDate: members[i].startDate,
                            endDate: members[i].endDate,
                            fee: members[i].subscriptionAmount,
                            batch: members[i].className
                        }
                        data.push(obj)
                    }
                    this.setState({
                        enrolled: data
                    })
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchPrepaidMembers() {
        fetch(Api.fetchPrepaidMembers(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let members = response.response;
                    let data = []
                    for (let i = 0; i < members.length; i++) {
                        let obj = {
                            key: i,
                            name: members[i].name,
                            sno: (i + 1),
                            number: members[i].number,
                            email: members[i].email,
                            startDate: members[i].startDate
                        }
                        data.push(obj)
                    }
                    this.setState({
                        prepaid: data
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchRenewalGoneMembers() {
        fetch(Api.fetchRenewalGoneMembers(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let members = response.response;
                    let data = []
                    for (let i = 0; i < members.length; i++) {
                        let obj = {
                            key: members[i].id,
                            name: members[i].name,
                            sno: (i + 1),
                            number: members[i].number,
                            email: members[i].email,
                            startDate: members[i].startDate,
                            endDate: members[i].endDate
                        }
                        data.push(obj)
                    }
                    this.setState({
                        renewals: data
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    render() {
        return (
            <div style={{
                padding: "20px",
                minHeight: "80vh",
                overflow: "auto",
                position: "relative",
                width: "100%",
            }}>
                <div className="site-card-wrapper">
                    <Row gutter={16}>
                        <Col span={1}></Col>
                        <Col span={5}>
                            <Card title="Enrolled" bordered={false}>
                                {this.state.enrolled.length}
                            </Card>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}>
                            <Card title="Renewals" bordered={false}>
                                {this.state.renewals.length}
                            </Card>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}>
                            <Card title="Prepaid" bordered={false}>
                                {this.state.prepaid.length}
                            </Card>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}>
                            <Card title="Batches" bordered={false}>
                                {this.state.batchList.length}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Dashboard