import React, { Component } from "react";
import { Row, Col, Modal, Button, Input, Form } from 'antd';
import Api from "../../utils/Api";

class AddLiveClass extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visibleAddModal: false,
            loadingAddModal: false,
            
        }

        this.showAddModal = this.showAddModal.bind(this)
        this.cancelAdd = this.cancelAdd.bind(this)
        this.okAdd = this.okAdd.bind(this)

        this.addLiveClass = this.addLiveClass.bind(this)

    }

    showAddModal() {
        this.setState({
            visibleAddModal: true
        })
    }
    cancelAdd() {
        this.setState({
            visibleAddModal: false
        })
    }
    okAdd() {
        this.setState({
            loadingAddModal: true
        })
    }
    addLiveClass(e) {
        console.log(e)
        fetch(Api.addLiveClass(), {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                name: e.className
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error === false) {
                    this.setState({
                        visibleAddModal: false,
                        loadingAddModal: false,
                    })
                    Modal.success({
                        content: "Batch Added",
                    });
                } else {
                    this.setState({
                        visibleAddModal: false,
                        loadingAddModal: false,
                    })
                    Modal.error({
                        title: "Error",
                        content: "Batch Not Added",
                    });
                }


            })
            .catch((err) => {
                console.log(err)
                this.setState({
                    visibleAddModal: false,
                    loadingAddModal: false,
                })
                Modal.error({
                    title: "Error",
                    content: "Batch Not Added",
                });
            });
    }


    render() {
        return (
            <div style={{
                padding: "20px",
                minHeight: "80vh",
                overflow: "auto",
                position: "relative",
                width: "100%",
            }}>
                <Row>
                    
                    <Col span={24}>
                        <Button type="primary" onClick={this.showAddModal}>Add Batch</Button>
                    </Col>
                </Row>
                {/* Add Modal */}
                <Modal
                    title="Add Batch"
                    open={this.state.visibleAddModal}
                    onCancel={this.cancelAdd}
                    footer={[
                        <Button key="back" onClick={this.cancelAdd}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            form="classForm"
                            loading={this.state.loadingAddModal}
                            onClick={this.okAdd}
                        >
                            Add
                        </Button>,
                    ]}
                >
                    <Form
                        id="classForm"
                        name="classForm"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 14,
                        }}
                        onFinish={this.addLiveClass}
                        layout="horizontal"
                        labelAlign="left"
                        hideRequiredMark={true}
                    >

                        <Form.Item
                            label="Batch Name"
                            name="className"


                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
                
            </div>
        );
    }

}

export default AddLiveClass;