import React, { Component } from "react";
import { Button, Form, Input, DatePicker, Row, Col, Modal, Select, Checkbox } from 'antd';
import moment from "moment/moment";
import Api from "../../utils/Api";
const { Option } = Select;
class AddUser extends Component {


    constructor(props) {
        super(props);
        this.state = {
            startDate: moment(new Date()),
            batchList: [],
            fitnessList: [],
            checkedFit:[],
            medicalList:[],
            checkedMed:[],
            mentorsList:[]
        }
        this.addMember = this.addMember.bind(this)
        this.fetchAllClasses = this.fetchAllClasses.bind(this)
        this.fetchAllFitnessGoals = this.fetchAllFitnessGoals.bind(this)
        this.fetchAllMedicalConditions = this.fetchAllMedicalConditions.bind(this)
        this.fetchAllMentors = this.fetchAllMentors.bind(this)
    }
    componentDidMount() {
        this.fetchAllClasses()
        this.fetchAllFitnessGoals()
        this.fetchAllMedicalConditions()
        this.fetchAllMentors();
    }
    fetchAllClasses() {
        fetch(Api.fetchAllClasses(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let classes = response.response;
                    let data = []
                    for (let i = 0; i < classes.length; i++) {
                        let obj = {
                            id: classes[i].id,
                            name: classes[i].name
                        }
                        data.push(obj)
                    }
                    this.setState({
                        batchList: data
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchAllFitnessGoals() {
        fetch(Api.fetchAllFitnessGoals(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let fitnessGoals = response.response;
                    let data = []
                    for (let i = 0; i < fitnessGoals.length; i++) {
                        let obj = {
                            id: fitnessGoals[i].id,
                            name: fitnessGoals[i].name
                        }
                        data.push(obj)
                    }
                    this.setState({
                        fitnessList: data
                    })
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchAllMedicalConditions() {
        fetch(Api.fetchAllMedicalConditions(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let list = response.response;
                    let data = []
                    for (let i = 0; i < list.length; i++) {
                        let obj = {
                            id: list[i].id,
                            name: list[i].name,
                        }
                        data.push(obj)
                    }
                    this.setState({
                        medicalList: data
                    })
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchAllMentors() {
        fetch(Api.fetchAllMentors(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let mentors = response.response;
                    let data = []
                    for (let i = 0; i < mentors.length; i++) {
                        let obj = {
                            id: mentors[i].id,
                            name: mentors[i].name
                        }
                        data.push(obj)
                    }
                    this.setState({
                        mentorsList: data
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    addMember(e) {
        console.log("Call Api")
        console.log(e)
        fetch(Api.addMember(), {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                name: e.memberName,
                email: e.memberEmail,
                number: e.memberNumber,
                startDate: this.state.startDate.format("YYYY-MM-DD"),
                subscriptionMonths: e.subscriptionMonths,
                subscriptionAmount: e.subscriptionAmount,
                classId: e.batch,
                address: e.memberAddress,
                fitness:this.state.checkedFit.toString(),
                medical:this.state.checkedMed.toString(),
                mentorId:e.mentor
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    Modal.success({
                        title: "Info",
                        content: "Member Added",
                    });
                }else{
                    Modal.error({
                        title: "Info",
                        content: response.message,
                    });
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    onChange = (e, r) => {
        this.setState({
            startDate: e
        })
    }
    handleFitnessClick=(e)=>{
        if(this.state.checkedFit.includes(e.target.value)){
            this.state.checkedFit.splice(this.state.checkedFit.indexOf(e.target.value),1)
            console.log(this.state.checkedFit)
            console.log(this.state.checkedFit.toString())
        }else{
            this.state.checkedFit.push(e.target.value)
            console.log(this.state.checkedFit)
            console.log(this.state.checkedFit.toString())
        }
    }
    handleMedicalClick=(e)=>{
        if(this.state.checkedMed.includes(e.target.value)){
            this.state.checkedMed.splice(this.state.checkedMed.indexOf(e.target.value),1)
            console.log(this.state.checkedMed)
            console.log(this.state.checkedMed.toString())
        }else{
            this.state.checkedMed.push(e.target.value)
            console.log(this.state.checkedMed)
            console.log(this.state.checkedMed.toString())
        }
    }

    render() {
        return (

            <div style={{
                padding: "20px",
                minHeight: "80vh",
                overflow: "auto",
                position: "relative",
                width: "100%",
            }}>
                <Row>
                    <Col span={24}>

                    </Col>
                    <Col span={24}>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            layout="horizontal"
                            onFinish={this.addMember}
                            hideRequiredMark={true}
                        >
                            <Form.Item label="Name"
                                name="memberName"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Name",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Number"
                                name="memberNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Number",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Email"
                                name="memberEmail"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Email",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Address"
                                name="memberAddress"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Starting Date"
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Start Date",
                                    },
                                ]}
                            >
                                <DatePicker onChange={this.onChange} />
                            </Form.Item>
                            <Form.Item label="Subscription Months" name="subscriptionMonths" initialValue={1}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Subscription Amount" name="subscriptionAmount" initialValue={1500}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Batch" 
                            name="batch"
                            rules={[
                                {
                                    required: false,
                                    message: "Please Add Batch",
                                },
                            ]}
                            >
                                <Select placeholder="Please Choose Batch">
                                    {this.state.batchList.map((items) => (
                                        <Option value={items.id}>{items.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Mentor" 
                            name="mentor"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Add Mentor",
                                },
                            ]}
                            >
                                <Select placeholder="Please Choose Mentor">
                                    {this.state.mentorsList.map((items) => (
                                        <Option value={items.id}>{items.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Fitness">
                                {
                                    this.state.fitnessList.map((items) => (
                                        <Checkbox value={items.id} onClick={this.handleFitnessClick}>{items.name}</Checkbox>
                                    ))
                                }

                            </Form.Item>
                            <Form.Item label="Medical">
                                {
                                    this.state.medicalList.map((items) => (
                                        <Checkbox value={items.id} onClick={this.handleMedicalClick}>{items.name}</Checkbox>
                                    ))
                                }

                            </Form.Item>
                            <Form.Item label="">
                                <Button htmlType="submit" type="primary">Save</Button>
                            </Form.Item>
                        </Form>
                    </Col>

                </Row>
            </div>

        );
    }
}

export default AddUser