import React, { Component } from "react";
import { Table, Tabs,Input, Space, Button, Modal, Form, DatePicker } from "antd";
import {  Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
    SearchOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import Api from "../../utils/Api";

const{TabPane} = Tabs;

class Renewals extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingRenewModal: false,
            visibleRenewModal: false,
            currentRenewRecord: null,
            days:15,
            datasource: [],
            datasource2:[],
            columns: [
                {
                    title: 'S.No',
                    dataIndex: 'sno',
                    key: 'sno'
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    ...this.getColumnSearchProps("name"),
                    render: (text, record) => <Link className="nav-link" to={"/admin/member/" + record.key}>{text}</Link>
                },
                {
                    title: 'Number',
                    dataIndex: 'number',
                    key: 'number',
                    ...this.getColumnSearchProps("number")
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    ...this.getColumnSearchProps("email")
                },
                {
                    title: 'Start Date',
                    dataIndex: 'startDate',
                    key: 'startDate',
                    ...this.getColumnSearchProps("startDate"),
                    sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
                    

                },
                {
                    title: 'End Date',
                    dataIndex: 'endDate',
                    key: 'endtDate',
                    ...this.getColumnSearchProps("endDate"),
                    sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
                },
                {
                    title: 'Current Fee',
                    dataIndex: 'fee',
                    key: 'fee',
                    // defaultSortOrder: "descend",
                    sorter: (a, b) => a.fee - b.fee,
                },
                {
                    title: 'Batch',
                    dataIndex: 'batch',
                    key: 'batch',
                    ...this.getColumnSearchProps("batch"),
                    sorter: (a, b) => a.batch.localeCompare(b.batch),
                },
                {
                    title: 'Action',
                    key: 'operation',
                    fixed: 'right',
                    width: 100,
                    render: (text, record) => (
                        <>
                            <Button type="primary" onClick={() => this.showRenewModal(record)} > Renew </Button>
                            <Button type="primary" danger onClick={() => this.endMembership(record)} >Decline</Button>
                        </>

                    )
                }
            ]
        }
        this.fetchUpcomingRenewals = this.fetchUpcomingRenewals.bind(this)
        this.fetchRenewalGoneMembers = this.fetchRenewalGoneMembers.bind(this)
        this.showRenewModal = this.showRenewModal.bind(this)
        this.cancelRenew = this.cancelRenew.bind(this)
        this.okRenew = this.okRenew.bind(this)
        this.renewMember = this.renewMember.bind(this)

    }
    componentDidMount() {
        this.fetchUpcomingRenewals()
        this.fetchRenewalGoneMembers()
    }
    showRenewModal(e) {
        console.log(e)
        this.setState({
            currentRenewRecord: e,
            visibleRenewModal: true
        })
    }
    cancelRenew() {
        this.setState({
            currentRenewRecord: null,
            visibleRenewModal: false,
            loadingRenewModal: false,
        });
    }
    okRenew() {
        this.setState({
            loadingRenewModal: true,
        });
    }
    onChange = (e, r) => {
        console.log(e.w)
        console.log(r)
        this.setState({
            startDate: r
        })
    }

    renewMember(e) {
        console.log(e)
        fetch(Api.renewMember(), {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                memberId: this.state.currentRenewRecord.key,
                startDate: this.state.startDate,
                subscriptionMonths: e.subscriptionMonths,
                subscriptionAmount: e.subscriptionAmount
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error === false) {
                    this.setState({
                        visibleRenewModal: false,
                        loadingRenewModal: false,
                    });
                    Modal.success({
                        content: "Lead Added Successfully",
                    });
                } else {
                    this.setState({
                        visibleRenewModal: false,
                        loadingRenewModal: false,
                    });
                    Modal.error({
                        title: "Error",
                        content: "Server Error. Lead Not Added Successfully",
                    });
                }

            })
            .catch((err) => {
                console.log(err)
                this.setState({
                    visibleRenewModal: false,
                    loadingRenewModal: false,
                });
                Modal.error({
                    title: "Error",
                    content: "Server Error. Lead Not Added Successfully",
                });
            });
    }
    endMembership(e) {
        console.log(e)
        fetch(Api.endMembership(), {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                memberId: e.key
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error === false) {

                    Modal.success({
                        content: "Membership Ended",
                    });
                } else {

                    Modal.error({
                        title: "Error",
                        content: "Membership Not Ended",
                    });
                }

            })
            .catch((err) => {
                console.log(err)

                Modal.error({
                    title: "Error",
                    content: "Membership Not Ended",
                });
            });
    }
    fetchUpcomingRenewals() {
        fetch(Api.fetchUpcomingRenewals() +"/"+this.state.days, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let members = response.response;
                    let data = []
                    for (let i = 0; i < members.length; i++) {
                        let obj = {
                            key: members[i].id,
                            name: members[i].name,
                            sno: (i + 1),
                            number: members[i].number,
                            email: members[i].email,
                            startDate: members[i].startDate,
                            endDate: members[i].endDate,
                            fee: members[i].subscriptionAmount,
                            batch: members[i].className
                        }
                        data.push(obj)
                    }
                    this.setState({
                        datasource: data
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchRenewalGoneMembers() {
        fetch(Api.fetchRenewalGoneMembers(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let members = response.response;
                    let data = []
                    for (let i = 0; i < members.length; i++) {
                        let obj = {
                            key: members[i].id,
                            name: members[i].name,
                            sno: (i + 1),
                            number: members[i].number,
                            email: members[i].email,
                            startDate: members[i].startDate,
                            endDate: members[i].endDate,
                            fee: members[i].subscriptionAmount,
                            batch: members[i].className
                        }
                        data.push(obj)
                    }
                    this.setState({
                        datasource2: data
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    handleChange=(pagination, filter, sorter, extra)=>{
        console.log(pagination, filter, sorter, extra)
    }
    render() {
        return (
            <div style={{
                padding: "20px",
                minHeight: "80vh",
                overflow: "auto",
                position: "relative",
                width: "100%",
            }}>
                <Tabs defaultActiveKey="1">
                    <TabPane
                    
                        tab={
                            <span>
                                <CheckCircleOutlined />
                                Upcoming
                            </span>
                        }
                        key="1"
                    >
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.datasource}
                            onChange={this.handleChange}
                        />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <ExclamationCircleOutlined />
                                Gone
                            </span>
                        }
                        key="2"
                    >
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.datasource2}
                            onChange={this.handleChange}
                        />
                    </TabPane>
                </Tabs>


                {/* Renew Modal */}
                <Modal
                    title="Renew"
                    open={this.state.visibleRenewModal}
                    onCancel={this.cancelRenew}
                    footer={[
                        <Button key="back" onClick={this.cancelRenew}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            form="renewForm"
                            loading={this.state.loadingRenewModal}
                            onClick={this.okRenew}
                        >
                            Renew
                        </Button>,
                    ]}
                >
                    <Form
                        id="renewForm"
                        name="renewForm"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 14,
                        }}
                        onFinish={this.renewMember}
                        layout="horizontal"
                        labelAlign="left"
                        hideRequiredMark={true}
                    >
                        <Form.Item
                            label="Starting Date"
                            name="startDate"
                            rules={[
                                {
                                    required: false,
                                    message: "Please Enter Start Date",
                                },
                            ]}
                        >
                            <DatePicker

                                onChange={this.onChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Subscription Months"
                            name="subscriptionMonths"
                        >
                            <Input defaultValue={1} />
                        </Form.Item>
                        <Form.Item
                            label="Subscription Amount"
                            name="subscriptionAmount"
                        >
                            <Input defaultValue={1500} />
                        </Form.Item>
                    </Form>
                </Modal>

            </div>

        );
    }
}

export default Renewals