import React, { Component } from "react";
import { Row, Col, Descriptions, Button, Card } from 'antd';
import { Form, Modal, Input, Select, DatePicker, Checkbox } from 'antd';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from "moment/moment";
import Api from "../../utils/Api";
const { Option } = Select;



class Member extends Component {
    constructor(props) {
        super(props)
        let href = window.location.href.split("/");
        this.state = {
            visibleBasicDetailModal: false,
            loadingBasicDetailModal: false,
            visibleSubscriptionDetailModal: false,
            loadingSubscriptionDetailModal: false,
            visibleMentorModal: false,
            loadingMentorModal: false,
            memberId: href[5],
            member: {},
            memberClass: {},
            batchList: [],
            fitnessList: [],
            checkedFit: [],
            fitnessGoals: [],
            medicalList: [],
            checkedMed: [],
            medicalConditions: [],
            mentorsList: [],
            conversationRemark: ""
        }

        this.fetchMemberById = this.fetchMemberById.bind(this)

        this.showUpdateBasicDetailsModal = this.showUpdateBasicDetailsModal.bind(this)
        this.cancelBasicDetailUpdate = this.cancelBasicDetailUpdate.bind(this)
        this.okBasicDetailUpdate = this.okBasicDetailUpdate.bind(this)
        this.updateMemberBasicDetails = this.updateMemberBasicDetails.bind(this)


        this.showUpdateSubscriptionDetailsModal = this.showUpdateSubscriptionDetailsModal.bind(this)
        this.cancelSubscriptionDetailUpdate = this.cancelSubscriptionDetailUpdate.bind(this)
        this.okSubscriptionDetailUpdate = this.okSubscriptionDetailUpdate.bind(this)
        this.updateMemberSubscriptionDetails = this.updateMemberSubscriptionDetails.bind(this)


        this.showMentorModal = this.showMentorModal.bind(this)
        this.cancelMentorUpdate = this.cancelMentorUpdate.bind(this)
        this.okMentorUpdate = this.okMentorUpdate.bind(this)
        this.updateMemberMentor = this.updateMemberMentor.bind(this)

        this.fetchAllFitnessGoals = this.fetchAllFitnessGoals.bind(this)
        this.fetchAllMedicalConditions = this.fetchAllMedicalConditions.bind(this)
        this.updateConversationRemark = this.updateConversationRemark.bind(this)
        this.saveConversationRemark = this.saveConversationRemark.bind(this)

        this.deleteMember = this.deleteMember.bind(this)

    }

    componentDidMount() {
        this.fetchAllClasses()
        this.fetchAllFitnessGoals()
        this.fetchAllMedicalConditions()
        this.fetchAllMentors()
        this.fetchMemberById()


    }

    fetchAllClasses() {
        fetch(Api.fetchAllClasses(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let classes = response.response;
                    let data = []
                    for (let i = 0; i < classes.length; i++) {
                        let obj = {
                            id: classes[i].id,
                            name: classes[i].name
                        }
                        data.push(obj)
                    }
                    this.setState({
                        batchList: data
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchMemberById() {
        fetch(Api.fetchMemberById()+"/" + this.state.memberId, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    console.log(response.response)
                    this.setState({
                        member: response.response,
                        memberClass: this.state.batchList.find(e => e.name = response.response.className ),
                        fitnessGoals: response.response.fitnessGoals,
                        medicalConditions: response.response.medicalConditions,
                        conversationRemark: response.response.conversationRemark,
                    })

                }

            })
            .then(() => {
                console.log("Class", this.state.memberClass)
                console.log("Goals", this.state.fitnessGoals)
            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchAllFitnessGoals() {
        fetch(Api.fetchAllFitnessGoals(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let fitnessGoals = response.response;
                    let data = []
                    for (let i = 0; i < fitnessGoals.length; i++) {
                        let obj = {
                            id: fitnessGoals[i].id,
                            name: fitnessGoals[i].name
                        }
                        data.push(obj)
                    }
                    this.setState({
                        fitnessList: data
                    })
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchAllMedicalConditions() {
        fetch(Api.fetchAllMedicalConditions(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let medicalConditions = response.response;
                    let data = []
                    for (let i = 0; i < medicalConditions.length; i++) {
                        let obj = {
                            id: medicalConditions[i].id,
                            name: medicalConditions[i].name
                        }
                        data.push(obj)
                    }
                    this.setState({
                        medicalList: data
                    })
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }
    fetchAllMentors() {
        fetch(Api.fetchAllMentors(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let mentors = response.response;
                    let data = []
                    for (let i = 0; i < mentors.length; i++) {
                        let obj = {
                            id: mentors[i].id,
                            name: mentors[i].name
                        }
                        data.push(obj)
                    }
                    this.setState({
                        mentorsList: data
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }
    showUpdateBasicDetailsModal() {
        this.setState({
            visibleBasicDetailModal: true
        })
    }
    cancelBasicDetailUpdate() {
        this.setState({
            visibleBasicDetailModal: false
        })
    }
    okBasicDetailUpdate() {
        this.setState({
            loadingBasicDetailModal: true
        })
    }
    updateMemberBasicDetails(e) {
        console.log(this.state.memberId)
        console.log(e)
        if (e.name === "") {
            this.setState({
                visibleBasicDetailModal: false,
                loadingBasicDetailModal: false,
            });
            Modal.error({
                title: "Error",
                content: "Name Cannot be empty",
            });
        } else {
            fetch(Api.updateMemberBasicDetails(), {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                },
                body: JSON.stringify({
                    id: this.state.memberId,
                    name: e.name,
                    number: e.number,
                    email: e.email
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log(response)
                    if (response.error !== undefined && response.error === false) {
                        this.setState({
                            visibleBasicDetailModal: false,
                            loadingBasicDetailModal: false,
                        });
                        Modal.success({
                            content: "Member Updated",
                        });

                        this.fetchMemberById()
                    } else {
                        this.setState({
                            visibleBasicDetailModal: false,
                            loadingBasicDetailModal: false,
                        });
                        Modal.error({
                            title: "Error",
                            content: "Member Not Updated",
                        });
                    }

                })
                .catch((err) => {
                    console.log(err)
                    this.setState({
                        visibleBasicDetailModal: false,
                        loadingBasicDetailModal: false,
                    });
                    Modal.error({
                        title: "Error",
                        content: "Member Not Updated",
                    });
                });
        }

    }
    showUpdateSubscriptionDetailsModal() {
        this.setState({
            visibleSubscriptionDetailModal: true
        })
    }
    cancelSubscriptionDetailUpdate() {
        this.setState({
            visibleSubscriptionDetailModal: false
        })
    }
    okSubscriptionDetailUpdate() {
        this.setState({
            loadingSubscriptionDetailModal: true
        })
    }
    updateMemberSubscriptionDetails(e) {
        console.log(e)
        fetch(Api.updateMemberSubscriptionDetails(), {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                id: this.state.memberId,
                startDate: e.startDate.format("YYYY-MM-DD"),
                endDate: e.endDate.format("YYYY-MM-DD"),
                amount: e.amount,
                classId: e.batch
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error === false) {
                    this.setState({
                        visibleSubscriptionDetailModal: false,
                        loadingSubscriptionDetailModal: false,
                    });
                    Modal.success({
                        content: "Member Updated",
                    });

                    this.fetchMemberById()
                } else {
                    this.setState({
                        visibleSubscriptionDetailModal: false,
                        loadingSubscriptionDetailModal: false,
                    });
                    Modal.error({
                        title: "Error",
                        content: "Member Not Updated",
                    });
                }

            })
            .catch((err) => {
                console.log(err)
                this.setState({
                    visibleSubscriptionDetailModal: false,
                    loadingSubscriptionDetailModal: false,
                });
                Modal.error({
                    title: "Error",
                    content: "Member Not Updated",
                });
            });
    }
    memberToggleFitnessGoal = (e) => {
        console.log(e.target.value)
        console.log(e.target.checked)
        if (e.target.checked) {
            // Add Fitness Goal
            fetch(Api.memberAddFitnessGoal(), {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                },
                body: JSON.stringify({
                    memberId: this.state.memberId,
                    fitnessGoalId: e.target.value
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log(response)
                    if (response.error !== undefined && response.error === false) {
                        Modal.success({
                            content: "Fitness Goal Added",
                        });
                        this.fetchMemberById()
                    } else {
                        Modal.error({
                            title: "Error",
                            content: "Fitness Goal Not Added",
                        });
                    }

                })
                .catch((err) => {
                    console.log(err)
                    Modal.error({
                        title: "Error",
                        content: "Fitness Goal Not Added",
                    });
                });
        } else {
            // Remove Fitness Goal
            fetch(Api.memberRemoveFitnessgoal(), {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                },
                body: JSON.stringify({
                    memberId: this.state.memberId,
                    fitnessGoalId: e.target.value
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log(response)
                    if (response.error !== undefined && response.error === false) {
                        Modal.success({
                            content: "Fitness Goal Removed",
                        });

                        this.fetchMemberById()
                    } else {
                        Modal.error({
                            title: "Error",
                            content: "Fitness Goal Not Removed",
                        });
                    }

                })
                .catch((err) => {
                    console.log(err)
                    Modal.error({
                        title: "Error",
                        content: "Fitness Goal Not Removed",
                    });
                });
        }

    }
    memberToggleMedicalCondition = (e) => {
        console.log(e.target.value)
        console.log(e.target.checked)
        if (e.target.checked) {
            // Add Fitness Goal
            fetch(Api.memberAddMedicalCondition(), {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                },
                body: JSON.stringify({
                    memberId: this.state.memberId,
                    medicalConditionId: e.target.value
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log(response)
                    if (response.error !== undefined && response.error === false) {
                        Modal.success({
                            content: "Medical Condition Added",
                        });
                        this.fetchMemberById()
                    } else {
                        Modal.error({
                            title: "Error",
                            content: "Medical Condition Not Added",
                        });
                    }

                })
                .catch((err) => {
                    console.log(err)
                    Modal.error({
                        title: "Error",
                        content: "Medical Condition Not Added",
                    });
                });
        } else {
            // Remove Fitness Goal
            fetch(Api.memberRemoveMedicalCondition(), {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                },
                body: JSON.stringify({
                    memberId: this.state.memberId,
                    medicalConditionId: e.target.value
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log(response)
                    if (response.error !== undefined && response.error === false) {
                        Modal.success({
                            content: "Medical Condition Removed",
                        });

                        this.fetchMemberById()
                    } else {
                        Modal.error({
                            title: "Error",
                            content: "Medical Condition Not Removed",
                        });
                    }

                })
                .catch((err) => {
                    console.log(err)
                    Modal.error({
                        title: "Error",
                        content: "Medical Condition Not Removed",
                    });
                });
        }

    }
    showMentorModal() {
        this.setState({
            visibleMentorModal: true
        })
    }
    cancelMentorUpdate() {
        this.setState({
            visibleMentorModal: false
        })
    }
    okMentorUpdate() {
        this.setState({
            loadingMentorModal: true
        })
    }
    updateMemberMentor(e) {
        fetch(Api.updateMemberMentor(), {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                id: this.state.memberId,
                mentorId: e.mentor
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error === false) {
                    this.setState({
                        visibleMentorModal: false,
                        loadingMentorModal: false,
                    });
                    Modal.success({
                        content: "Member Updated",
                    });

                    this.fetchMemberById()
                } else {
                    this.setState({
                        visibleMentorModal: false,
                        loadingMentorModal: false,
                    });
                    Modal.error({
                        title: "Error",
                        content: "Member Not Updated",
                    });
                }

            })
            .catch((err) => {
                console.log(err)
                this.setState({
                    visibleMentorModal: false,
                    loadingMentorModal: false,
                });
                Modal.error({
                    title: "Error",
                    content: "Member Not Updated",
                });
            });
    }
    updateConversationRemark(e) {
        this.setState({
            conversationRemark: e
        })
    }
    saveConversationRemark() {
        fetch(Api.saveConversationRemark(), {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                id: this.state.memberId,
                remark: this.state.conversationRemark
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error === false) {

                    Modal.success({
                        content: "Member Updated",
                    });

                    this.fetchMemberById()
                } else {
                    Modal.error({
                        title: "Error",
                        content: "Member Not Updated",
                    });
                }

            })
            .catch((err) => {
                console.log(err)
                Modal.error({
                    title: "Error",
                    content: "Member Not Updated",
                });
            });
    }
    deleteMember(){
        fetch(Api.deleteMember(), {
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                memberId: this.state.memberId,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                window.location="/admin"

            })
            .catch((err) => {
                console.log(err)
                Modal.error({
                    title: "Error",
                    content: "Member Not Deleted",
                });
            });
    }
    render() {
        return (
            <div style={{
                padding: "20px",
                minHeight: "80vh",
                overflow: "auto",
                position: "relative",
                width: "100%",
            }}>
                <Row>
                    <Col span={24}>
                        <Card>
                            <Descriptions title="User Info" extra={<Button type="primary" onClick={this.showUpdateBasicDetailsModal}>Edit</Button>}>
                                <Descriptions.Item label="Name">{this.state.member.name != null ? this.state.member.name : "k"} </Descriptions.Item>
                                <Descriptions.Item label="Number">{this.state.member.number}</Descriptions.Item>
                                <Descriptions.Item label="Email">{this.state.member.email}</Descriptions.Item>
                                <Descriptions.Item label="Address">{this.state.member.address}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card>
                            <Descriptions title="Subscription Details" extra={<Button type="primary" onClick={this.showUpdateSubscriptionDetailsModal}>Edit</Button>}>
                                <Descriptions.Item label="Start Date">{this.state.member.startDate}</Descriptions.Item>
                                <Descriptions.Item label="End Date">{this.state.member.endDate}</Descriptions.Item>
                                <Descriptions.Item label="Amount">{this.state.member.subscriptionAmount}</Descriptions.Item>
                                <Descriptions.Item label="Batch Name">{this.state.member.className}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card title="Fitness Goals">
                            {
                                this.state.fitnessList.map((items) => (
                                    <Checkbox value={items.id} onClick={this.memberToggleFitnessGoal}
                                        checked={this.state.fitnessGoals.find(e => e.id === items.id) != null ? true : false}
                                    >{items.name}</Checkbox>
                                ))
                            }
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card title="Medical Conditions">
                            {
                                this.state.medicalList.map((items) => (
                                    <Checkbox value={items.id} onClick={this.memberToggleMedicalCondition}
                                        checked={this.state.medicalConditions.find(e => e.id === items.id) != null ? true : false}
                                    >{items.name}</Checkbox>
                                ))
                            }
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card title="Mentor" extra={<Button type="primary" onClick={this.showMentorModal}>Edit</Button>}>
                            <p>{this.state.member.mentorName}</p>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card title="Remark" extra={<Button type="primary" onClick={this.saveConversationRemark}>Save</Button>}>
                            <ReactQuill theme="snow" value={this.state.conversationRemark} onChange={this.updateConversationRemark} />
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card>
                            <Button type="primary" onClick={this.deleteMember}>Remove User</Button>
                        </Card>
                    </Col>
                </Row>

                {/* User Detail Modal */}
                <Modal
                    title="User Info"
                    open={this.state.visibleBasicDetailModal}
                    onCancel={this.cancelBasicDetailUpdate}
                    footer={[
                        <Button key="back" onClick={this.cancelBasicDetailUpdate}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            form="basicDetailForm"
                            loading={this.state.loadingBasicDetailModal}
                            onClick={this.okBasicDetailUpdate}
                        >
                            Update
                        </Button>,
                    ]}
                >
                    <Form
                        id="basicDetailForm"
                        name="basicDetailForm"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 14,
                        }}
                        onFinish={this.updateMemberBasicDetails}
                        layout="horizontal"
                        labelAlign="left"
                        hideRequiredMark={true}
                    >

                        <Form.Item
                            label="Name"
                            name="name"
                            initialValue={this.state.member.name}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Number"
                            name="number"
                            initialValue={this.state.member.number}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            initialValue={this.state.member.email}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>

                {/* Subscription Detail Modal */}
                <Modal
                    title="Subscription Detail"
                    open={this.state.visibleSubscriptionDetailModal}
                    onCancel={this.cancelSubscriptionDetailUpdate}
                    footer={[
                        <Button key="back" onClick={this.cancelSubscriptionDetailUpdate}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            form="subscriptionDetailForm"
                            loading={this.state.loadingSubscriptionDetailModal}
                            onClick={this.okSubscriptionDetailUpdate}
                        >
                            Update
                        </Button>,
                    ]}
                >
                    <Form
                        id="subscriptionDetailForm"
                        name="subscriptionDetailForm"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 14,
                        }}
                        onFinish={this.updateMemberSubscriptionDetails}
                        layout="horizontal"
                        labelAlign="left"
                        hideRequiredMark={true}
                    >

                        <Form.Item
                            label="Start Date"
                            name="startDate"
                            initialValue={moment(new Date(this.state.member.startDate))}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label="End Date"
                            name="endDate"
                            initialValue={moment(new Date(this.state.member.endDate))}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label="Amount"
                            name="amount"
                            initialValue={this.state.member.subscriptionAmount}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Batch"
                            name="batch" >
                            <Select placeholder="Please Choose Batch"  >
                                {this.state.batchList.map((items) => (
                                    <Option value={items.id}>{items.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* Mentor Update Modal */}
                <Modal
                    title="Assign Mentor"
                    open={this.state.visibleMentorModal}
                    onCancel={this.cancelMentorUpdate}
                    footer={[
                        <Button key="back" onClick={this.cancelMentorUpdate}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            form="mentorForm"
                            loading={this.state.loadingMentorModal}
                            onClick={this.okMentorUpdate}
                        >
                            Update
                        </Button>,
                    ]}
                >
                    <Form
                        id="mentorForm"
                        name="mentorForm"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 14,
                        }}
                        onFinish={this.updateMemberMentor}
                        layout="horizontal"
                        labelAlign="left"
                        hideRequiredMark={true}
                    >
                        <Form.Item
                            label="Mentor"
                            name="mentor" >
                            <Select placeholder="Please Choose Mentor" initialValue={this.state.member.mentorName}>
                                {this.state.mentorsList.map((items) => (
                                    <Option value={items.id}>{items.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

            </div>
        );
    }
}

export default Member