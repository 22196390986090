import React, { Component } from "react";
import { Table, Input, Space, Button,Row, Col } from "antd";
import {  Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
    SearchOutlined
} from "@ant-design/icons";
import Api from "../../utils/Api";
class OldUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datasource: [],
            columns: [
                {
                    title: 'S.No',
                    dataIndex: 'sno',
                    key: 'sno'
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    ...this.getColumnSearchProps("name")
                },
                {
                    title: 'Number',
                    dataIndex: 'number',
                    key: 'number',
                    ...this.getColumnSearchProps("number")
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    ...this.getColumnSearchProps("email")
                },
                {
                    title: 'Start Date',
                    dataIndex: 'startDate',
                    key: 'startDate',
                    ...this.getColumnSearchProps("startDate"),
                    sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
                },
                {
                    title: 'End Date',
                    dataIndex: 'endDate',
                    key: 'endtDate',
                    ...this.getColumnSearchProps("endDate"),
                    // defaultSortOrder: "descend",
                    sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
                },
                {
                    title: 'Current Fee',
                    dataIndex: 'fee',
                    key: 'fee',
                    // defaultSortOrder: "descend",
                    sorter: (a, b) => a.fee - b.fee,
                },
                {
                    title: 'Batch',
                    dataIndex: 'batch',
                    key: 'batch',
                    ...this.getColumnSearchProps("batch"),
                    sorter: (a, b) => a.batch.localeCompare(b.batch),
                },
                {
                    title: 'Action',
                    key: 'operation',
                    fixed: 'right',
                    width: 100,
                    render: (text, record) => <Link className="nav-link" to={"/admin/member/" + record.key}>open</Link>,
                }
            ]
        }
        this.fetchExpiredMembers = this.fetchExpiredMembers.bind(this)
    }
    componentDidMount() {
        this.fetchExpiredMembers()
    }
    fetchExpiredMembers() {
        fetch(Api.fetchExpiredMembers(), {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            }
        }).then((response) => response.json())
            .then((response) => {
                console.log(response)
                if (response.error !== undefined && response.error !== true) {
                    let members = response.response;
                    let data = []
                    for (let i = 0; i < members.length; i++) {
                        let obj = {
                            key: members[i].id,
                            name: members[i].name,
                            sno: (i + 1),
                            number: members[i].number,
                            email: members[i].email,
                            startDate: members[i].startDate,
                            endDate: members[i].endDate,
                            fee: members[i].subscriptionAmount,
                            batch: members[i].className
                        }
                        data.push(obj)
                    }
                    this.setState({
                        datasource: data
                    })
                } 
            })
            .catch((err) => {
                console.log(err)
            });
    }
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    render() {
        return (
            <div style={{
                padding: "20px",
                minHeight: "80vh",
                overflow: "auto",
                position: "relative",
                width: "100%",
            }}>
                <Row>
                    {/* <Col span={24}>
                        <Button type="primary" onClick={this.fetchExpiredMembers}>Load List</Button>
                    </Col> */}
                    <Col span={24}>
                        <Table dataSource={this.state.datasource} columns={this.state.columns} />
                    </Col>

                </Row>
            </div>
        );
    }
}

export default OldUsers