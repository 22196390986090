import http from "./http";

class Api{
    serverUrl(){
        return http.getBaseUrl() + "/";
    }
    // Members
    addMember(){
        return http.getBaseUrl() + "/member/addMember";
    }
    fetchAllMembers(){
        return http.getBaseUrl() + "/member/fetchAllMembers";
    }
    fetchEnrolledMembers(){
        return http.getBaseUrl() + "/member/fetchAllMembers";
    }
    fetchPrepaidMembers(){
        return http.getBaseUrl() + "/member/fetchPrepaidMembers";
    }
    fetchRenewalGoneMembers(){
        return http.getBaseUrl() + "/member/fetchRenewalGoneMembers";
    }
    fetchExpiredMembers(){
        return http.getBaseUrl() + "/member/fetchExpiredMembers";
    }
    fetchMemberById(){
        return http.getBaseUrl() + "/member/fetchMemberById";
    }
    fetchUpcomingRenewals(){
        return http.getBaseUrl() + "/member/fetchUpcomingRenewals";
    }


    updateMemberBasicDetails(){
        return http.getBaseUrl() + "/member/updateMemberBasicDetails";
    }
    updateMemberSubscriptionDetails(){
        return http.getBaseUrl() + "/member/updateMemberSubscriptionDetails";
    }
    memberAddFitnessGoal(){
        return http.getBaseUrl() + "/member/addFitnessGoal";
    }
    memberRemoveFitnessgoal(){
        return http.getBaseUrl() + "/member/removeFitnessGoal";
    }
    memberAddMedicalCondition(){
        return http.getBaseUrl() + "/member/addMedicalCondition";
    }
    memberRemoveMedicalCondition(){
        return http.getBaseUrl() + "/member/removeMedicalCondition";
    }
    updateMemberMentor(){
        return http.getBaseUrl() + "/member/updateMentorDetails";
    }
    saveConversationRemark(){
        return http.getBaseUrl() + "/member/updateConversationRemark";
    }
    endMembership(){
        return http.getBaseUrl() + "/member/endMembership";
    }
    renewMember(){
        return http.getBaseUrl() + "/member/renewMember";
    }

    //Delete Member
    deleteMember(){
        return http.getBaseUrl() + "/member/deleteMember";
    }

    // Batches
    fetchAllClasses(){
        return http.getBaseUrl() + "/class/fetchAllLiveClasses";
    }
    fetchMembersByClassId(){
        return http.getBaseUrl() + "/class/fetchMembersByClassId";
    }
    addLiveClass(){
        return http.getBaseUrl() + "/class/addLiveClass";
    }
    removeLiveClass(){
        return http.getBaseUrl() + "/class/removeLiveClass";
    }
    updateLiveClassName(){
        return http.getBaseUrl() + "/class/updateLiveClassName";
    }




    // Fitness Goals
    fetchAllFitnessGoals(){
        return http.getBaseUrl() + "/fitness/fetchAllFitnessGoals";
    }
    fetchMembersByFitnessId(){
        return http.getBaseUrl() + "/fitness/fetchMembersByFitnessId";
    }
    addFitnessGoal(){
        return http.getBaseUrl() + "/fitness/addFitnessGoal"
    }
    removeFitnessGoal(){
        return http.getBaseUrl() + "/fitness/removeFitnessGoal"
    }
    updateFitnessGoalName(){
        return http.getBaseUrl() + "/fitness/updateFitnessGoalName"
    }



    // Medical Condition
    fetchAllMedicalConditions(){
        return http.getBaseUrl() + "/medical/fetchAllMedicalConditions";
    }
    fetchMembersByMedicalId(){
        return http.getBaseUrl() + "/medical/fetchMembersByMedicalId";
    }
    addMedicalCondition(){
        return http.getBaseUrl() + "/medical/addMedicalCondition";
    }
    removeMedicalCondition(){
        return http.getBaseUrl() + "/medical/removeMedicalCondition";
    }
    updateMedicalConditionName(){
        return http.getBaseUrl() + "/medical/updateMedicalConditionName";
    }



    // Mentors
    fetchAllMentors(){
        return http.getBaseUrl() + "/mentor/fetchAllMentors";
    }
    fetchMembersByMentorId(){
        return http.getBaseUrl() + "/mentor/fetchMembersByMentorId";
    }
    addMentor(){
        return http.getBaseUrl() + "/mentor/addMentor";
    }
    removeMentor(){
        return http.getBaseUrl() + "/mentor/removeMentor";
    }
    updateMentorName(){
        return http.getBaseUrl() + "/mentor/updateMentorName";
    }
    


    // Enrollment File
    fetchAllEntries(){
        return http.getBaseUrl() + "/enrollment/fetchAllEntries";
    }
    sendPdf(){
        return http.getBaseUrl() + "/enrollment/sendFile";
    }
}

export default new Api();